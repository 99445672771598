import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  // Text,
  // Link,
  // VStack,
  // Code,
  // Grid,
  theme,
} from '@chakra-ui/react';
// import { Logo } from './Logo';

import { Spinner } from '@chakra-ui/react';

import Navbar from "./components/navbar";
import Footer from "./components/footer";


function App() {

  const Homepage = React.lazy(() => import("./Homepage"));
  const Success = React.lazy(() => import("./Success"));
  const PrivacyPolicyAndTermsOfUse = React.lazy(() => import("./PrivacyPolicyAndTermsOfUse"));

  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">
        <Router>

          <Navbar />
          <Routes>
            <Route path='/' element={
              <React.Suspense fallback={<Spinner className='m-auto' />}>
                <Homepage />
              </React.Suspense>
            } />
            <Route path='/success' element={
              <React.Suspense fallback={<Spinner className='m-auto' />}>
                <Success />
              </React.Suspense>
            } />
            <Route path='/privacy-policy-and-terms-of-use' element={
              <React.Suspense fallback={<Spinner className='m-auto' />}>
                <PrivacyPolicyAndTermsOfUse />
              </React.Suspense>
            } />
            {/* Add other routes here */}
          </Routes>
          {/* <Grid minH="100vh">

          <Homepage /> */}

          {/* <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.js</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link>
          </VStack> */}
          {/* </Grid> */}
          <Footer />
        </Router>

      </Box>
    </ChakraProvider>
  );
}

export default App;
