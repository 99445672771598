import {
    Box,
    // chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    // VisuallyHidden,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
// import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

// const Logo = (props) => {
//     return (
//         <Link to="/">
//             <img style={{ maxHeight: "42px" }} src='./logo/Color-logo-no-background.svg' alt="Logo" />
//         </Link>
//     );
// };

const ProvacyPolicyAndTermsOfUseButton = (props) => {
    return (
        <Link to="/privacy-policy-and-terms-of-use">
                Privacy Policy and Terms of Use
        </Link>
    );
};

// const SocialButton = ({ children, label, href }) => {
//     return (
//         <chakra.button
//             bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//             rounded={'full'}
//             w={8}
//             h={8}
//             cursor={'pointer'}
//             as={'a'}
//             href={href}
//             display={'inline-flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             transition={'background 0.3s ease'}
//             _hover={{
//                 bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//             }}
//         >
//             <VisuallyHidden>{label}</VisuallyHidden>
//             {children}
//         </chakra.button>
//     );
// };

export default function Footer() {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}
            >
                <ProvacyPolicyAndTermsOfUseButton />
                <Text className='m-0 text-white text-center'>© {currentYear}, 14335767 Canada Inc. or its affiliates. All rights reserved.</Text>
                <Stack direction={'row'} spacing={6}>
                    {/* <SocialButton label={'YouTube'} href={'#'}>
                        <FaYoutube />
                    </SocialButton>
                    <SocialButton label={'Instagram'} href={'#'}>
                        <FaInstagram />
                    </SocialButton> */}
                </Stack>
            </Container>
        </Box>
    );
}
